import { AbstractControl } from '@angular/forms';

import * as zxcvbn from 'zxcvbn';

export class PasswordValidator {
  static PasswordRegex: string = '(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])(?=[\W|_]*).{8,}';

  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if ( password !== confirmPassword ) {
      AC.get('confirmPassword').setErrors( {MatchPassword: true} );
    } else if (confirmPassword) {
      AC.get('confirmPassword').setErrors(null);
    }
  }

  static PasswordStrength(password: string) {
    const res = zxcvbn(password);

    return (res.score + 1 ) * password.length / 5;
  }

}
